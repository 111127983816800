// GA
// import ReactGA from 'react-ga4';

// styling
import './style.scss';

// libs styles
import 'swiper/css';
import 'swiper/css/effect-fade';
// import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.min.css';
// utils
import React, { lazy, Suspense, useEffect } from 'react';
import {preventDefault} from '@utils/helpers';

// import { CloudinaryContext } from 'cloudinary-react';


// context
import {BidModalContextAPI} from '@contexts/bidModalContext';
import {SidebarContextAPI} from '@contexts/sidebarContext';
import {AuthAPI} from '@contexts/authContext';

// components
import LoadingScreen from '@components/LoadingScreen';
import AppLayout from '@components/AppLayout';
import {Routes, Route} from 'react-router-dom';
import ScrollToTop from '@components/ScrollToTop';

// pages
const Home = lazy(() => import('@pages/Home'));
// const Home2 = lazy(() => import('@pages/Home2'));
// const Explore = lazy(() => import('@pages/Explore'));
// const ExploreGrid = lazy(() => import('@pages/ExploreGrid'));
// const Item = lazy(() => import('@pages/Item'));
// const Author = lazy(() => import('@pages/Author'));
// const Profile = lazy(() => import('@pages/Profile'));
// const FAQ = lazy(() => import('@pages/FAQ'));
// const Ranking = lazy(() => import('@pages/Ranking'));
// const Activity = lazy(() => import('@pages/Activity'));
// const ConnectWallet = lazy(() => import('@pages/ConnectWallet'));
// const Login = lazy(() => import('@pages/Login'));
// const About = lazy(() => import('@pages/About'));
// const Team = lazy(() => import('@pages/Team'));
// const BlogSidebar = lazy(() => import('@pages/BlogSidebar'));
// const BlogGrid = lazy(() => import('@pages/BlogGrid'));
// const Post = lazy(() => import('@pages/Post'));
// const Contacts = lazy(() => import('@pages/Contacts'));
const PageNotFound = lazy(() => import('@pages/PageNotFound'));

const App = () => {
    useEffect(() => {
        preventDefault();
    }, []);


    useEffect(() => {
        // Function to load GTM script
        const loadGTM = (id) => {
            const script = document.createElement('script');
            script.async = true;
            script.src = `https://www.googletagmanager.com/gtm.js?id=${id}`;
            document.head.appendChild(script);
        };

        // Load GTM script after a delay (e.g., 2 seconds)
        const timer = setTimeout(() => {
            loadGTM(process.env.REACT_APP_PUBLIC_GA); // Replace 'YOUR_GTM_ID' with your actual GTM ID
        }, 2000); // Adjust the delay as needed

        // Cleanup function to clear the timeout if the component unmounts
        return () => clearTimeout(timer);
    }, []);

    // const gaKey = process.env.REACT_APP_PUBLIC_GA;
    // gaKey && ReactGA.initialize(gaKey);


    // useEffect(() => {
    //     const gaKey = process.env.REACT_APP_PUBLIC_GA;
    //     if (gaKey) {
    //         ReactGA.initialize(gaKey, {
    //             gaOptions: {
    //                 siteSpeedSampleRate: 100,
    //             },
    //         });
    //     }
    // }, []);

    return (
        <AuthAPI>
            <BidModalContextAPI>
                <SidebarContextAPI>
                    <ScrollToTop/>
                    <AppLayout>
                        <Suspense fallback={<LoadingScreen visible/>}>
                            <Routes>
                                <Route path="/" element={<Home/>}/>
                                {/*<Route path="/index2" element={<Home2/>}/>
                                <Route path="/explore" element={<Explore/>}/>
                                <Route path="/explore-grid" element={<ExploreGrid/>}/>
                                <Route path="/explore/item" element={<Item/>}/>
                                <Route path="/author" element={<Author/>}/>
                                <Route path="/profile" element={<Profile/>}/>
                                <Route path="/faq" element={<FAQ/>}/>
                                <Route path="/ranking" element={<Ranking/>}/>
                                <Route path="/activity" element={<Activity/>}/>
                                <Route path="/connect-wallet" element={<ConnectWallet/>}/>
                                <Route path="/login" element={<Login/>}/>
                                <Route path="/about" element={<About/>}/>
                                <Route path="/team" element={<Team/>}/>
                                <Route path="/blog-sidebar" element={<BlogSidebar/>}/>
                                <Route path="/blog-grid" element={<BlogGrid/>}/>
                                <Route path="/post" element={<Post/>}/>
                                <Route path="/contacts" element={<Contacts/>}/>*/}
                                <Route path="*" element={<PageNotFound/>}/>
                            </Routes>
                        </Suspense>
                    </AppLayout>
                </SidebarContextAPI>
            </BidModalContextAPI>
        </AuthAPI>
    )
}

export default App
